export const addressShortener = (a?: string | null) => {
  if (!a) return "NULL";
  const b = a[0] === "0" && a[1] === "x" ? a.substring(2, a.length) : a;

  return (b[0] + b[1] + b[2] + b[b.length - 3] + b[b.length - 2] + b[b.length - 1]).toUpperCase();
};

export const hashShortener = (a?: string | null) => {
  if (!a) return "NULL";
  return (
    a[0] +
    a[1] +
    a[2] +
    a[3] +
    "..." +
    a[a.length - 4] +
    a[a.length - 3] +
    a[a.length - 2] +
    a[a.length - 1]
  );
};

enum AddressType {
  P2SH = "p2sh",
  P2TR = "p2tr",
  Stacks = "stacks",
  Ethereum = "eth",
  EthTxHash = "ethTxHash",
  P2WPKH = "P2WPKH",
  P2WSH = "P2WSH",
  P2PKH = "P2PKH",
}

const detectAddressType = (address: string): AddressType | undefined => {
  // See: https://unchained.com/blog/bitcoin-address-types-compared/
  if (address.length === 34 && address.startsWith("3")) return AddressType.P2SH;
  if (address.startsWith("1") && address.length >= 26 && address.length <= 34)
    return AddressType.P2PKH;
  if (address.length === 42 && address.startsWith("bc1q")) return AddressType.P2WPKH;
  if (address.length === 62 && address.startsWith("bc1p")) return AddressType.P2TR;
  if (address.length === 62 && address.startsWith("bc1q")) return AddressType.P2WSH;

  // Ethereum
  if (address.length === 42 && address.startsWith("0x")) return AddressType.Ethereum;
  if (address.length === 66 && address.startsWith("0x")) return AddressType.EthTxHash;

  // I've observed both 40 and 41 character stacks addresses that are valid. If you can find conclusive evidence
  // that one or the other is correct, please update this. Also note, the second letter is a versioning character
  // and I don't think its worth it for us to care about the version letter, so only check that it starts with 'S'.
  // 40 EX: STJRM2AMVF90ER6G3RW1QTF85E3HZH37006D5ER1
  if (address.length === 40 && address.startsWith("ST")) return AddressType.Stacks;

  // 41 EX: SM2J6ZY48GV1EZ5V2V5RB9MP66SW86PYKKQVX8X0G
  // 41 EX: SP134ZZVQ66EA0T0BW64FW691H90VAAVV926774HD
  // 41 EX: SZ2J6ZY48GV1EZ5V2V5RB9MP66SW86PYKKQ9H6DPR
  if (
    address.length === 41 &&
    (address.startsWith("SM") || address.startsWith("SP") || address.startsWith("SZ"))
  )
    return AddressType.Stacks;

  // Fallback if no pattern matches
  return undefined;
};

const SIG_DIG_CONST = 4;

export const prettyBtcAddress = (address: string, addressType: AddressType): string => {
  // See: https://bitcoin.design/guide/glossary/address4
  switch (addressType) {
    // BTC Types, Follow XVerse Pattern
    case AddressType.P2SH:
    case AddressType.P2PKH:
      /* pay-to-script-hash (P2SH), or script address are 34 chars ex: 3J98t1WpEZ73CNmQviecrnyiWrnqRhWNLy  */
      /* pay-to-public-key-hash (P2PKH), or script address are typically 33 or 34 chars but can be as few as 26 ex: 12higDjoCCNXSA95xZMWUdPvXNmkAduhWv  */
      return (
        address.substring(0, SIG_DIG_CONST + 1) +
        "..." +
        address.substring(address.length - SIG_DIG_CONST)
      );

    case AddressType.P2WPKH:
    case AddressType.P2TR:
    case AddressType.P2WSH:
      /* script address starts with bc1X: bc1pfjalxkg53s43vkazav6e7wregpkcz5wyp8dasts9vrepp6dcnmuqtq7f52  */
      return (
        address.substring(0, SIG_DIG_CONST + 4) +
        "..." +
        address.substring(address.length - SIG_DIG_CONST)
      );

    case AddressType.Stacks:
      /* Stacks wallets are 40 or 41 chars. 
      Ex: 
      STJRM2AMVF90ER6G3RW1QTF85E3HZH37006D5ER1 
      SP134ZZVQ66EA0T0BW64FW691H90VAAVV926774HD
      */
      return (
        address.substring(0, SIG_DIG_CONST + 2) +
        "..." +
        address.substring(address.length - SIG_DIG_CONST)
      );

    default:
      throw new Error("Unsupported BTC address type");
  }
};

export const prettyEthAddress = (
  address: string,
  addressType: AddressType = AddressType.Ethereum
): string => {
  if (addressType === AddressType.EthTxHash) {
    return (
      address.substring(0, SIG_DIG_CONST + 2) +
      "..." +
      address.substring(address.length - SIG_DIG_CONST)
    );
  }

  return (
    address.substring(0, SIG_DIG_CONST + 2) +
    "..." +
    address.substring(address.length - SIG_DIG_CONST)
  );
};

/**
 * @deprecated Use {@link prettyAddress} instead.
 */
export const prettyAddressShortener = (addressType: string, address: string): string => {
  return prettyAddress(address);
};

/**
 * Pretty Address - Supports BTC, Stacks, ETH wallets, and ETH transactions. A one-stop-shop.
 *
 * This function will work in principle for any decodable blockchain value with a unique prefix+length combination.
 *
 * This function should return the minimum length string for a given input. If more information is needed that is not
 * provided by the minimum length string, then write a new function.
 *
 * @param address
 * @returns A short, nicely formatted string representing the address or hash.
 */
export const prettyAddress = (address: string | null | undefined): string => {
  if (!address) return "NULL";

  const detectedType = detectAddressType(address);
  if (!detectedType) {
    // Just return the address here, don't throw. This way the user's page doesn't break.
    console.error(`Could not detect address type: ${JSON.stringify(address)}`);
    return address;
  }
  if (detectedType === AddressType.Ethereum || detectedType === AddressType.EthTxHash) {
    return prettyEthAddress(address, detectedType);
  } else {
    return prettyBtcAddress(address, detectedType);
  }
};
